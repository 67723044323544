@tailwind base;
@tailwind components;
@tailwind utilities;

.base-background {
    background-image: url("assets/images/background1.webp");
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
}

.color_layer {
    background-color: rgba(8, 8, 11, 0.7);
    position: sticky;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.sr-only { /* all screens */
    clip: rect(0, 0, 0, 0);
    border: 0;
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}

@font-face {
    font-family: "fridge";
    src: local("refrigerator-deluxe"),
    url("./assets/fonts/RefrigeratorDeluxeHeavy_8349567.ttf") format("truetype");
}

.ability_font {
    font-family: fridge, sans-serif;
    font-style: normal;
    font-weight: 300;
}

.fridge {
    font-family: fridge, sans-serif;
    font-style: normal;
}

